<template>

  <div class="mobile-list">
    <div class="mobile-list-top flex-col" :style='bg'>
      <div class="flex-row fill-row ">
        <Menu mode="horizontal" theme="primary" active-name="2" @on-select="menuclick">
          <MenuItem name="1" style="width: 33.33%;">
          <Icon />
          我的名片
          </MenuItem>
          <MenuItem name="2" style="width: 33.33%; text-align: center;">
          <Icon />
          我的任务
          </MenuItem>
          <MenuItem name="3" style="width: 33.33%; text-align: center;">
          <Icon />
          我的业绩
          </MenuItem>
        </Menu>
      </div>
      <div class="flex-row  margin-buttom-10 ">
        <div class="flex-col fill-row3">
          <label class="text-center margin-top-10 white-font-color">1</label>
          <label class="text-center margin-top-10 white-font-color" style=" margin-bottom: 5px;">进行中</label>
        </div>
        <div class="flex-col fill-row3">
          <label class="text-center margin-top-10 white-font-color">2</label>
          <label class="text-center margin-top-10 white-font-color" style=" margin-bottom: 5px;">预期任务</label>
        </div>
        <div class="flex-col fill-row3">
          <label class="text-center margin-top-10 white-font-color">3</label>
          <label class="text-center margin-top-10 white-font-color" style=" margin-bottom: 5px;">已完成</label>
        </div>
      </div>
    </div>
    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <taskbox :datas="items" :columns="columns" @click="itemClick" />
      </orderScroll>
    </div>
  </div>

</template>

<script>
import bg_img from "@/static/images/bg_Rectangle.png"
import { formatDate, formatMoney } from "@/utils/format.js";
export default {

  data: function () {
    return {
      theme1: 'light',
      disabled: true,
      bg: {
        background: `url(${bg_img})`,
        backgroundSize: '100% 100%'
      },
      items: [

      ],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      },
      enterkey: [],
      keyword: "",
      columns: [
        {
          type: "name_key_rightkey",
          name: "项目名称",
          nameStyle: "color:#999",
          key: "parojectName",
          keyStyle: "black",
          rightkey: "stateName",
          rightkeyStyle: "color:red;",
        },
        {
          type: "name_timekey",
          name: "开始时间",
          nameStyle: "color:#999",
          key: "stratTime",
          keyStyle: "color:black",
        },
        {
          type: "name_timekey",
          name: "预计结束时间",
          nameStyle: "color:#999",
          key: "estimatedEndTime",
          keyStyle: "color:black",
        },
        {
          type: "name_key_rightkey",
          name: "紧急程度",
          nameStyle: "color:#999;margin-bottom:10px",
          key: "urgentState",
          keyStyle: "color:black;margin-bottom:10px",
          rightkey: "department",
          rightkeyStyle: "color:#999; margin-bottom:10px",
        },

      ]
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    menuclick(name) {
      let url = ""
      if (name == "1") {
        url = "mycard";
      } else if (name == "2") {
        url = "mytask";
      } else if (name == "3") {
        url = "myperformance";
      }
      this.$router.push({
        name: url,
      });
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    add: function () {
    },
    itemClick: function (item) {
      // this.$router.push({
      //   name: "mCarCostDetail",
      //   query: {
      //     id: item.id,
      //   },
      // });
      console.log(item);
    },
    click_checkChange(ii) {
      console.log(ii);
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    filterClicked: function () {
      this.showingFilter = true;
      this.$nextTick(() => {
        this.$refs.searchDrawer.show();
      });
    },
    filterBgClicked: function () {
      this.showingFilter = false;
    },
    resetFilter: function () {
      this.filterOption = {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: 1,
        orderSourceKey: null,
      };
      this.showingFilter = false;
      this.firstPage();
    },
    confirmFilter: function () {
      this.filterOption = this.$refs.filter.getOption();
      this.showingFilter = false;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      this.items = [
        {
          parojectName: "lims系统",
          stateName: "进行中",
          stratTime: "2021/12/14",
          estimatedEndTime: "2022/12/14",
          urgentState: "重要不紧急",
          department: "开发部",
        },
        {
          parojectName: "OA系统",
          stateName: "进行中",
          stratTime: "2021/12/14 11:22:30",
          estimatedEndTime: "2022/12/14 11:22:30",
          urgentState: "重要不紧急",
          department: "开发部",
        },
        {
          parojectName: "秸秆禁烧",
          stateName: "进行中",
          stratTime: "2021/12/14 11:22:30",
          estimatedEndTime: "2022/12/14 11:22:30",
          urgentState: "重要不紧急",
          department: "开发部",
        },]
      // this.$refs.scroll.forceUpdate(true);
    }
  },
  mounted: function () {
    this.firstPage();
  },
};
</script>

<style lang="scss" scoped>
.mobile-input-disabled {
  .title {
    margin-left: 5px !important;
    padding-left: 6px !important;
    text-align: left;
  }
}
</style>
<style scoped>
.ivu-menu-item-active {
  color: white;
  border-bottom: 2px solid white;
}

.ivu-menu-primary {
  background: none;
}

.page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  flex: 0 0 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top_top {
  flex: 0 0 50%;
}
.top_bottom {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  text-align: center;
}
.top_bottom_left {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
}
.top_bottom_centent {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
}
.top_bottom_right {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
}
.lable {
  height: 20px;
  margin-top: 10px;
  color: white;
}

.img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
</style>